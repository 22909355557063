import React from "react";
import { Link } from "react-router-dom";
import "../css/HeaderAdmin.css";

export default function HeaderAdmin({ username }) {
  return (
    <div className="header">
      <Link to="/home" className="home-link">
        <h1>Portail Admin</h1>
      </Link>
      <div className="navbar">
        <Link to="/admin" className="navbar-link">
          Accueil
        </Link>
        <Link to="/admin/user" className="navbar-link">
          User
        </Link>
        <Link to="/admin/site" className="navbar-link">
          Site
        </Link>
        <Link to="/admin/droit" className="navbar-link">
          Droit
        </Link>
      </div>
    </div>
  );
}
