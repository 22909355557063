import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import bcrypt from "bcryptjs";
import { supabase } from "./components/supabase";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

//function to generate a random access token
function generateAccessToken() {
  return Math.random().toString(36).substr(2, 15);
}

async function loginUser(username, password) {
  try {
    const { data: connexion, error } = await supabase
      .from("connexion")
      .select("IdConnexion, Login, Password, type_user(LibType)")
      .eq("Login", username);

    console.log(supabase);
    console.log(connexion);
    if (error) {
      // Gérer l'erreur
      return { message: "An error occurred" };
    } else {
      if (connexion.length === 0) {
        return { message: "User not found" };
      } else {
        const validPassword = await bcrypt.compare(
          password,
          connexion[0].Password
        );
        console.log(password, connexion[0].Password, validPassword);
        if (validPassword) {
          return {
            message: "User logged in successfully",
            accessToken: generateAccessToken(),
            user: {
              id: connexion[0].IdConnexion,
              username: connexion[0].Login,
              type: connexion[0].type_user.LibType,
            },
          };
        } else {
          return { message: "Wrong password" };
        }
      }
    }
  } catch (error) {
    // Gérer l'erreur
    return { message: "An error occurred" };
  }
}

export default function Signin() {
  const classes = useStyles();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword); // Inverser l'état pour afficher ou masquer le mot de passe
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser(username, password);
    if ("accessToken" in response) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("user", JSON.stringify(response.user));
        window.location.href = "/home";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image} />
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connectez-vous
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              label="Identifiant"
              onChange={(e) => setUserName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Mot de passe"
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Connexion
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
