import React from "react";
import { supabase } from "../components/supabase";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderAdmin from "../components/HeaderAdmin";
import Footer from "../components/Footer";

export default function DroitForm() {
  const [droits, setDroits] = useState([]);
  const [users, setUsers] = useState([]);
  const [sites, setSites] = useState([]);
  const [droitsParUtilisateur, setDroitsParUtilisateur] = useState({});
  const userStorage = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const initialFormState = {
    connexion_id: users.key,
    site_id: [],
  };

  const [droit, setDroit] = useState(initialFormState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDroit({ ...droit, [name]: value });
  };

  const handleCheckboxChange = (event, siteId) => {
    const { checked } = event.target;
    const updatedSites = [...droit.site_id]; // Créer une copie de la liste des sites sélectionnés

    if (checked) {
      // Ajouter le site à la liste des sites sélectionnés
      if (!updatedSites.includes(siteId)) {
        updatedSites.push(siteId);
      }
    } else {
      // Retirer le site de la liste des sites sélectionnés
      const index = updatedSites.indexOf(siteId);
      if (index !== -1) {
        updatedSites.splice(index, 1);
      }
    }

    setDroit({ ...droit, site_id: updatedSites });
  };

  const handleLoginClick = (loginId, login) => {
    console.log(loginId);
    const selectedDroit = droits[login];
    if (selectedDroit && selectedDroit.length > 0) {
      const selectedSites = selectedDroit.map((droit) => droit.idsite);
      setDroit({
        ...droit,
        connexion_id: loginId[0].idconnexion,
        site_id: selectedSites,
      });
    }
  };

  useEffect(
    () => {
      fetchDroits();
      fetchLogin();
      fetchSite();
      const droitsParUtilisateurData = {};
      Object.keys(droits).forEach((login) => {
        droitsParUtilisateurData[login] = droits[login].length;
      });
      setDroitsParUtilisateur(droitsParUtilisateurData);
      if (!userStorage || userStorage.type !== "Admin") {
        navigate("/"); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté ou n'est pas de type admin
      }
    },
    [droits],
    [userStorage, navigate]
  );

  async function fetchDroits() {
    const { data } = await supabase
      .from("droit")
      .select(
        "connexion_id, site_id, connexion(IdConnexion, Login), site(IdSite, LibelleSite)"
      );

    // Regrouper les données par "login"
    const groupedDroits = data.reduce((acc, droit) => {
      const key = `${droit.connexion_id}-${droit.connexion.Login}`; // Utiliser idconnexion et login comme clé de regroupement
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push({
        idconnexion: droit.connexion_id,
        idsite: droit.site_id,
        libelleSite: droit.site.LibelleSite,
      });
      return acc;
    }, {});

    setDroits(groupedDroits);
  }

  async function fetchLogin() {
    const { data } = await supabase
      .from("connexion")
      .select("IdConnexion, Login, type_user(LibType)");
    setUsers(data);
  }

  async function fetchSite() {
    const { data } = await supabase.from("site").select("IdSite, LibelleSite");
    setSites(data);
  }

  const addDroit = async () => {
    if (!droit.connexion_id || droit.site_id.length === 0) {
      console.error("Veuillez remplir tous les champs requis.");
      return;
    }

    try {
      for (let i = 0; i < droit.site_id.length; i++) {
        // Utiliser la fonction de requête de Supabase pour insérer chaque option dans la base de données
        const { data, error } = await supabase.from("droit").insert([
          {
            connexion_id: droit.connexion_id,
            site_id: droit.site_id[i],
          },
        ]);
        if (error) {
          console.error(error);
        } else {
          console.log(data); // Afficher la réponse de la requête
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateDroit = async (idconnexion, idsite, updatedDroit) => {
    const { data, error } = await supabase
      .from("droit")
      .update(updatedDroit)
      .eq("connexion_id", idconnexion)
      .eq("site_id", idsite);
    if (error) {
      console.error(error);
    } else {
      setDroits(
        droits.map(
          (droit) => droit.connexion_id === idconnexion,
          droit.site_id === idsite ? updatedDroit : droit
        )
      );
    }
  };

  const deleteDroit = async (idconnexion, idsite) => {
    if (!droits) {
      console.error("Droits data is not available.");
      return;
    }

    const updatedDroits = { ...droits };
    if (updatedDroits[idconnexion]) {
      updatedDroits[idconnexion] = updatedDroits[idconnexion].filter(
        (droit) => droit.idsite !== idsite
      );
      setDroits(updatedDroits);
    } else {
      console.error(
        "Droits data for the specified idconnexion is not available."
      );
    }

    const { error } = await supabase
      .from("droit")
      .delete()
      .eq("connexion_id", idconnexion)
      .eq("site_id", idsite);
    if (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <HeaderAdmin />
      <h2>Ajouter/Modifier/Supprimer Droit</h2>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (!droit.connexion_id || droit.site_id.length === 0) return;
          if (
            droit.connexion_id &&
            droit.site_id.length !== droitsParUtilisateur.length
          ) {
            addDroit();
          } else {
            updateDroit(droit.connexion_id, droit.site_id, droit);
          }
        }}
      >
        <label>Users</label>
        <select
          name="connexion_id"
          value={droit.connexion_id}
          onChange={handleInputChange}
        >
          <option value="">Sélectionner un type</option>
          {users.map((user) => (
            <option key={user.IdConnexion} value={user.IdConnexion}>
              {user.Login} - {user.type_user.LibType}
            </option>
          ))}
        </select>
        <label>Site</label>
        {sites.map((site) => (
          <div key={site.IdSite}>
            <input
              type="checkbox"
              key={site.IdSite}
              name="site_id"
              value={site.IdSite}
              checked={droit.site_id && droit.site_id.includes(site.IdSite)}
              onChange={(e) => handleCheckboxChange(e, site.IdSite)}
            />
            <label htmlFor={site.IdSite}>{site.LibelleSite}</label>
          </div>
        ))}
        &emsp;
        <button>Ajouter/Modifier</button>
      </form>
      <table>
        <thead>
          <tr>
            <th>Login</th>
            <th>Liste des Libellé Site</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(droits).map((login) => (
            <tr key={login}>
              <td>
                <button onClick={() => handleLoginClick(droits[login], login)}>
                  {login}
                </button>
              </td>
              <td>
                <ul>
                  {droits[login].map((droit, index) => (
                    <li key={index}>
                      {droit.libelleSite}
                      &emsp;
                      <button
                        onClick={() =>
                          deleteDroit(droit.idconnexion, droit.idsite)
                        }
                      >
                        Supprimer
                      </button>
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Footer />
    </div>
  );
}
