import { supabase } from "./components/supabase";
import { useEffect, useState } from "react";
//import "./Home.css";
import Card from "./components/Card";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useNavigate } from "react-router-dom";

export default function Home() {
  //je veux récupérer les droits de l'utilisateur et afficher les boutons en fonction de ses droits
  const user = JSON.parse(localStorage.getItem("user"));
  const [droits, setDroits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const containerStyle = {
    display: "flex",
    justifyContent: "center", // Center the cards horizontally
    alignItems: "flex-start", // Align the cards at the top of the container
    flexWrap: "wrap",
  };

  const boxStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around", // Space the cards evenly within the container
    alignItems: "flex-start",
    padding: "50px 0",
  };

  const fetchDroits = async () => {
    try {
      const { data, error: fetchError } = await supabase
        .from("droit")
        .select(
          "connexion_id, site_id, connexion(Login), site(LibelleSite, link, color)"
        )
        .eq("connexion_id", user.id);
      if (fetchError) {
        console.log(fetchError);
        throw fetchError;
      } else {
        setDroits(data);
        console.log(data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/"); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    } else {
      fetchDroits();
    }
  }, [user, navigate]);

  return (
    <div>
      {user && <Header username={user.username} />}
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error.message}</div>
      ) : (
        <div style={containerStyle}>
          <div style={boxStyle}>
            {droits.map((droit) => (
              <Card
                key={droit.Connexion_id}
                libelle={droit.site.LibelleSite}
                lien={droit.site.link}
                couleur={droit.site.color}
                totalCartes={droits.length}
              />
            ))}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
