import React, { useState } from "react";
import { IconButton, Avatar } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { Link } from "react-router-dom";
import "../css/Header.css";
import { useNavigate } from "react-router-dom";
import AlertAbout from "./AlertAbout";

export default function Header({ username }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    //localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <div className="header">
      <Link to="/profile">
        <div className="avatar-dropdown">
          {username && <Avatar alt={username} src="/path/to/avatar.jpg" />}
          <div className="dropdown-content">
            <Link to="/profile">Profil</Link>
            <Link onClick={handleLogout}>Déconnexion</Link>
          </div>
        </div>
      </Link>
      <h1>Portail</h1>
      <IconButton onClick={() => AlertAbout()}>
        <HelpIcon />
      </IconButton>
    </div>
  );
}
