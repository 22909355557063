import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useNavigate } from "react-router-dom";
import HeaderProfil from "./components/HeaderProfil";
import Footer from "./components/Footer";
import { Button } from "@material-ui/core";
import UpdatePasswordForm from "./components/UpdatePassword";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 20, // Ajout de bordures arrondies
    border: "2px solid #3f51b5", // Ajout d'une bordure
    padding: "10px 20px",
  },
}));

export default function Profile() {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const handlePasswordChange = () => {
    // Logique pour la modification du mot de passe
    navigate("/update-password");
  };

  useEffect(() => {
    if (!user) {
      navigate("/"); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    }
  }, [user, navigate]);

  return (
    <div className={classes.root}>
      <HeaderProfil />
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography variant="h5">Bienvenue {user.username}</Typography>
        </CardContent>
      </Card>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={handlePasswordChange}
      >
        Modifier le mot de passe
      </Button>
      <Footer />
    </div>
  );
}
