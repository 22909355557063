import React from "react";
import "./css/App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signin from "./Signin";
import Profile from "./Profile";
import Home from "./Home";
import Admin from "./Admin";
import UserForm from "./admin/User";
import SiteForm from "./admin/Site";
import DroitForm from "./admin/Droit";
import UpdatePasswordForm from "./components/UpdatePassword";

function App() {
  const token = localStorage.getItem("accessToken");

  if (!token) {
    return <Signin />;
  }

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/" element={<Signin />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/admin/user" element={<UserForm />}></Route>
          <Route path="/admin/site" element={<SiteForm />}></Route>
          <Route path="/admin/droit" element={<DroitForm />}></Route>
          <Route
            path="/update-password"
            element={<UpdatePasswordForm />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
