import React, { useState, useEffect } from "react";
import { supabase } from "../components/supabase";
import { useNavigate } from "react-router-dom";
import HeaderAdmin from "../components/HeaderAdmin";
import Footer from "../components/Footer";
import bcrypt from "bcryptjs";

export default function UserForm() {
  const [users, setUsers] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [stringPassword, setStringPassword] = useState(""); // Ajout d'un état pour stocker le mot de passe haché

  const userStorage = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const initialFormState = {
    IdConnexion: null,
    Login: "",
    Password: "",
    email: "",
    type_id: "",
  };
  const [user, setUser] = useState(initialFormState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const selectUser = (selectedUser) => {
    setUser({
      ...selectedUser,
      type_id: selectedUser.type_id, // Assurez-vous que type_id est correctement mis à jour
    });
  };

  const resetForm = () => {
    setUser(initialFormState);
  };

  const sendEmailViaPHP = async (userMail) => {
    console.log(userMail);
    try {
      const response = await fetch(
        "https://samuelbeucler.fr/servermail/portail/sendmail.php",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userMail),
        }
      );

      if (response.ok) {
        console.log("E-mail envoyé avec succès !");
      } else {
        console.error(
          "Erreur lors de l'envoi de l'e-mail :",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'e-mail :", error);
    }
  };

  useEffect(
    () => {
      fetchUsers();
      fetchUserTypes();
      if (!userStorage || userStorage.type !== "Admin") {
        navigate("/"); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté ou n'est pas de type admin
      }
    },
    [],
    [userStorage, navigate]
  );

  const fetchUsers = async () => {
    const { data, error } = await supabase.from("connexion").select("*");
    if (error) {
      console.error(error);
    } else {
      setUsers(data);
    }
  };

  const fetchUserTypes = async () => {
    // Remplacer 'userTypes' par le nom de votre table contenant les types d'utilisateur
    const { data, error } = await supabase.from("type_user").select("*");
    if (error) {
      console.error(error);
    } else {
      setUserTypes(data);
    }
  };

  function generatePassword(length) {
    const characters =
      "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%&()*+,-./:;<=>?@[\\]^_{|}~";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }
    return password;
  }

  const hashPassword = async (password) => {
    try {
      const saltRounds = 10; // Nombre de tours de salage
      const hashedPassword = await bcrypt.hash(password, saltRounds);
      return hashedPassword;
    } catch (error) {
      // Gérer les erreurs
      console.error("Erreur lors du hachage du mot de passe:", error);
      return null;
    }
  };

  const addUser = async () => {
    if (!user.Login || !user.email || !user.type_id) {
      console.error("Veuillez remplir tous les champs requis.");
      return;
    }

    const passwordToHash = generatePassword(12);
    console.log("Password:", user.Password);
    user.Password = passwordToHash;
    console.log("Password après:", user.Password);
    console.log("Mot de passe généré:", passwordToHash);
    try {
      const hashedPassword = await hashPassword(passwordToHash);
      setStringPassword(hashedPassword);
      console.log("Mot de passe haché:", hashedPassword);

      const userData = {
        Login: user.Login,
        Password: hashedPassword,
        email: user.email,
        type_id: parseInt(user.type_id),
      };

      const userMail = {
        Login: user.Login,
        Password: passwordToHash,
        email: user.email,
      };

      //console.log(userData);
      //console.log(userMail);

      const { data, error } = await supabase
        .from("connexion")
        .insert([userData])
        .select();
      if (error) {
        console.error(error);
      } else {
        setUsers([...users, data[0]]);
        setUser(initialFormState);
        sendEmailViaPHP(userMail);
      }

      resetForm();
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  const updateUser = async (id, updatedUser) => {
    const { data, error } = await supabase
      .from("connexion")
      .update(updatedUser)
      .eq("IdConnexion", id);
    if (error) {
      console.error(error);
    } else {
      setUsers(
        users.map((user) => (user.IdConnexion === id ? updatedUser : user))
      );
    }

    resetForm();
  };

  const deleteUser = async (id) => {
    const { error } = await supabase
      .from("connexion")
      .delete()
      .eq("IdConnexion", id);
    if (error) {
      console.error(error);
    } else {
      setUsers(users.filter((user) => user.IdConnexion !== id));
    }

    resetForm();
  };

  return (
    <div>
      <HeaderAdmin />
      <h2>Ajouter/Modifier/Supprimer Utilisateur</h2>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (!user.Login || !user.email || !user.type_id) return;
          if (user.IdConnexion) {
            updateUser(user.IdConnexion, user);
          } else {
            addUser();
          }
        }}
      >
        <label>Nom</label>
        <input
          type="text"
          name="Login"
          value={user.Login}
          onChange={handleInputChange}
        />
        <label>Email</label>
        <input
          type="text"
          name="email"
          value={user.email}
          onChange={handleInputChange}
        />
        <label>Type d'utilisateur</label>
        <select
          name="type_id"
          value={user.type_id}
          onChange={handleInputChange}
        >
          <option value="">Sélectionner un type</option>
          {userTypes.map((type) => (
            <option key={type.IdType} value={type.IdType}>
              {type.LibType}
            </option>
          ))}
        </select>
        &emsp;
        <button>Ajouter/Modifier</button>
      </form>
      <div>
        <h2>Liste des Utilisateurs</h2>
        <ul>
          {users.map((user) => (
            <li key={user.IdConnexion} onClick={() => selectUser(user)}>
              {user.Login} - {user.email}
              &emsp;
              <button onClick={() => deleteUser(user.IdConnexion)}>
                Supprimer
              </button>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </div>
  );
}
