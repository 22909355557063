import React from "react";
import "../css/Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <a
        href="https://github.com/Adsam39"
        target="_blank"
        rel="noopener noreferrer"
      >
        BEUCLER Samuel
      </a>
      <br />
      <p>© 2023 - Portail</p>
    </div>
  );
}
