import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useNavigate } from "react-router-dom";
import HeaderProfil from "./HeaderProfil";
import Footer from "./Footer";
import { supabase } from "./supabase";
import bcrypt from "bcryptjs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    marginTop: theme.spacing(2),
  },
  card: {
    width: "45%",
    marginBottom: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Centrer les éléments dans le formulaire
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 20, // Ajout de bordures arrondies
    border: "2px solid #3f51b5", // Ajout d'une bordure
    padding: "10px 20px",
  },
  textField: {
    width: "100%", // Augmenter la largeur du champ de texte
    marginBottom: theme.spacing(2), // Ajouter un espacement en bas
  },
}));

export default function UpdatePasswordForm() {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  //fonction pour hasher le mot de passe
  const hashPassword = async (password) => {
    try {
      const saltRounds = 10; // Nombre de tours de salage
      const hashedPassword = await bcrypt.hash(password, saltRounds);
      return hashedPassword;
    } catch (error) {
      // Gérer les erreurs
      console.error("Erreur lors du hachage du mot de passe:", error);
      return null;
    }
  };

  const handlePasswordChange = async () => {
    // Logique pour la modification du mot de passe
    if (password === passwordConfirm) {
      setError(false);
      setHelperText("");
      setSuccess(true);
      setSuccessText("Mot de passe modifié avec succès");

      const hash = await hashPassword(password);

      console.log(hash);

      // Mettre à jour le mot de passe dans la base de données Supabase
      const { error } = await supabase
        .from("connexion") // Remplacez 'utilisateurs' par le nom de votre table
        .update({ Password: hash }) // Remplacez 'mot_de_passe' par le nom de votre champ de mot de passe
        .eq("IdConnexion", user.id); // Remplacez 'id' par le nom de votre champ d'identifiant utilisateur et 'utilisateurId' par l'identifiant de l'utilisateur actuel

      if (error) {
        console.error(
          "Erreur lors de la mise à jour du mot de passe:",
          error.message
        );
        // Gérer l'erreur, par exemple afficher un message à l'utilisateur
      } else {
        console.log("Mot de passe mis à jour avec succès");
        // Gérer la réussite, par exemple afficher un message à l'utilisateur
      }
    } else {
      setError(true);
      setHelperText("Les mots de passe ne correspondent pas");
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/"); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    }
  }, [user, navigate]);

  return (
    <div className={classes.root}>
      <HeaderProfil />
      <div className={classes.content}>
        <Typography variant="h4" gutterBottom>
          Modifier le mot de passe
        </Typography>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <form className={classes.form}>
              <TextField
                id="password"
                label="Mot de passe"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={error}
                helperText={helperText}
                required
                className={classes.textField}
              />
              &emsp;
              <TextField
                id="passwordConfirm"
                label="Confirmer le mot de passe"
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                error={error}
                helperText={helperText}
                required
                className={classes.textField}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handlePasswordChange}
              >
                Modifier
              </Button>
            </form>
          </CardContent>
        </Card>
        {success && (
          <Typography variant="h6" gutterBottom>
            {successText}
          </Typography>
        )}
      </div>
      <Footer />
    </div>
  );
}
