import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error("Missing env variables for supabase");
}
if (
  supabaseUrl === "YOUR_SUPABASE_URL" ||
  supabaseKey === "YOUR_SUPABASE_KEY"
) {
  throw new Error("Please update your env variables for supabase");
}
if (supabaseUrl === "undefined" || supabaseKey === "undefined") {
  throw new Error("Please update your env variables for supabase");
}
if (supabaseUrl === "" || supabaseKey === "") {
  throw new Error("Please update your env variables for supabase");
}
if (supabaseUrl === "null" || supabaseKey === "null") {
  throw new Error("Please update your env variables for supabase");
}

let supabase;
let schema;

if (process.env.REACT_APP_PROD === "true") {
  schema = "public";
} else if (process.env.REACT_APP_DEV === "true") {
  schema = "dev";
} else if (process.env.REACT_APP_TEST === "true") {
  schema = "test";
} else {
  throw new Error("Invalid environment specified");
}

supabase = createClient(supabaseUrl, supabaseKey, {
  db: { schema: schema },
});

// Maintenant, vous pouvez utiliser la variable 'schema' pour vérifier le schéma actuellement utilisé.
console.log("Le schéma de base de données actuellement utilisé est:", schema);

export { supabase };
