import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import HeaderAdmin from "./components/HeaderAdmin";
import Footer from "./components/Footer";

export default function Admin() {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user.type !== "Admin") {
      navigate("/"); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté ou n'est pas de type admin
    }
  }, [user, navigate]);

  const handleSiteClick = () => {
    navigate("/admin/site"); // Rediriger vers la page "moder" au clic sur le bouton "moder"
  };

  const handleUserClick = () => {
    navigate("/admin/user"); // Rediriger vers la page "user" au clic sur le bouton "user"
  };

  const handleDroitClick = () => {
    navigate("/admin/droit"); // Rediriger vers la page "droit" au clic sur le bouton "droit"
  };

  return (
    <div>
      <HeaderAdmin />
      <div className="button-container">
        <button className="moder-button" onClick={handleSiteClick}>
          Site
        </button>
        <button className="user-button" onClick={handleUserClick}>
          User
        </button>
        <button className="droit-button" onClick={handleDroitClick}>
          Droit
        </button>
      </div>
      <Footer />
    </div>
  );
}
