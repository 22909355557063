import swal from "sweetalert";

const version = process.env.REACT_APP_VERSION;

export default function AlertAbout() {
  swal({
    title: "Version du site " + version,
    text: "Le site est un portail pour accéder à du contenu créé par mes soins.",
    icon: "info",
    customclass: {
      text: ".swal-text",
    },
  });
  const style = document.createElement("style");
  style.type = "text/css";
  style.innerHTML = `
      .swal-text {
        font-size: 20px;
      }
    `;
  document.head.appendChild(style);
}
