import React from "react";
import "../css/Card.css";

export default function Card({ libelle, lien, couleur, totalCartes }) {
  /*const cardStyle = {
    flex: "0 0 calc(50% - 20px)", // Taille des cartes ajustée pour qu'elles occupent la moitié de la largeur et se répartissent côte à côte
    margin: "0 0 30px", // Espacement entre les cartes
    overflow: "hidden",
    borderRadius: "28px",
  };*/

  /*const cardStyle = {
    flex: "0 0 calc(33.333% - 20px)", // Each card takes up one-third of the width with some spacing
    margin: "0 10px 30px", // Adjusted spacing between cards
    overflow: "hidden",
    borderRadius: "28px",
  };*/
  const isImpair = totalCartes % 2 !== 0;

  const cardStyle = {
    flex: `0 0 calc(${
      isImpair ? 100 / (totalCartes - 1) : 100 / totalCartes
    }% - 20px)`, // Ajuster la taille en fonction du nombre de cartes
    margin: "0 10px 30px", // Ajuster l'espacement en fonction du nombre de cartes
    overflow: "hidden",
    borderRadius: "28px",
    minWidth: "200px",
  };

  const linkStyle = {
    display: "block",
    padding: "30px 20px",
    backgroundColor: "#121212",
    overflow: "hidden",
    position: "relative",
  };

  const titleStyle = {
    minHeight: "87px",
    margin: "0 0 25px",
    overflow: "hidden",
    fontWeight: "bold",
    fontSize: "30px",
    color: "#fff",
    zIndex: "2",
    position: "relative",
  };

  const bgStyle = {
    height: "128px",
    width: "128px",
    backgroundColor: couleur,
    zIndex: "1",
    position: "absolute",
    top: "-75px",
    right: "-75px",
    borderRadius: "50%",
    "-webkit-transition": "all 0.5s ease",
    "-o-transition": "all 0.5s ease",
    transition: "all 0.5s ease",
  };

  return (
    <div style={cardStyle}>
      <a href={lien} className="ag-courses-item_link" style={linkStyle}>
        <div className="ag-courses-item_bg" style={bgStyle}></div>
        <div style={titleStyle}>{libelle}</div>
      </a>
    </div>
  );
}
