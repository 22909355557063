import React, { useEffect, useState } from "react";
import { supabase } from "../components/supabase";
import { ChromePicker } from "react-color";
import { useNavigate } from "react-router-dom";
import HeaderAdmin from "../components/HeaderAdmin";
import Footer from "../components/Footer";

export default function SiteForm() {
  const [sites, setSites] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [color, setColor] = useState("#fff");
  const [imageUrl, setImageUrl] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const initialFormState = {
    IdSite: null,
    LibelleSite: "",
    link: "",
  };

  const [site, setSite] = useState(initialFormState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSite({ ...site, [name]: value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Mettez à jour le fichier sélectionné dans l'état
    console.log(event.target.files[0]);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor.hex); // Mettez à jour la couleur avec la nouvelle valeur sélectionnée
    console.log(color);
  };

  const handleSiteClick = async (selectedSite) => {
    setSite(selectedSite);
    setSelectedFile(null); // Réinitialiser l'image à sa valeur initiale
    setColor(selectedSite.color); // Mettre à jour la couleur avec la couleur du site sélectionné

    console.log(selectedSite.image);

    const { data, error } = await supabase.storage
      .from("image")
      .getPublicUrl(selectedSite.image);

    /*const { data, error } = await supabase.storage
      .from("image")
      .download(selectedSite.image);*/

    if (error) {
      console.error(
        "Erreur lors de la récupération de l'URL public de l'image :",
        error
      );
    } else {
      setImageUrl(data.publicUrl);
    }
  };

  useEffect(
    () => {
      fetchSites();
      if (!user || user.type !== "Admin") {
        navigate("/"); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté ou n'est pas de type admin
      }
    },
    [],
    [user, navigate]
  );

  const fetchSites = async () => {
    const { data, error } = await supabase.from("site").select("*");
    if (error) {
      console.error(error);
    } else {
      setSites(data);
    }
  };

  const uploadFile = async () => {
    if (selectedFile) {
      const { data, error } = await supabase.storage
        .from("image")
        .upload(selectedFile.name, selectedFile, {
          cacheControl: "3600",
          upsert: false,
        });
      if (error) {
        console.error("Erreur lors du téléchargement du fichier :", error);
      } else {
        console.log("Fichier téléchargé avec succès !");
      }
    } else {
      console.error("Aucun fichier sélectionné.");
    }
  };

  const addSite = async () => {
    if (!site.LibelleSite || !site.link || !color || !selectedFile) {
      console.error("Veuillez remplir tous les champs requis.");
      return;
    }
    console.log(site.LibelleSite);
    console.log(site.link);
    console.log(color);
    const siteData = {
      LibelleSite: site.LibelleSite,
      link: site.link,
      image: selectedFile.name,
      color: color,
    };

    console.log(siteData);

    const { data, error } = await supabase
      .from("site")
      .insert([siteData])
      .select();
    if (error) {
      console.error(error);
    } else {
      setSites([...sites, data[0]]);
      setSite(initialFormState);
      // Réinitialiser l'image à sa valeur initiale
      setSelectedFile(null);
      // Réinitialiser la couleur à sa valeur initiale
      setColor("#fff");
      await uploadFile();
    }
  };

  const updateSite = async (id, updatedSite) => {
    const { data, error } = await supabase
      .from("site")
      .update(updatedSite)
      .eq("IdSite", id);
    if (error) {
      console.error(error);
    } else {
      setSites(sites.map((site) => (site.IdSite === id ? updatedSite : site)));
    }
  };

  const deleteSite = async (id) => {
    const { error } = await supabase.from("site").delete().eq("IdSite", id);
    if (error) {
      console.error(error);
    } else {
      setSites(sites.filter((site) => site.IdSite !== id));
    }
  };

  return (
    <div>
      <HeaderAdmin />
      <h2>Ajouter/Modifier/Supprimer Site</h2>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (!site.LibelleSite || !site.link || !color || !selectedFile)
            return;
          if (site.IdSite) {
            updateSite(site.IdSite, site);
          } else {
            addSite();
          }
        }}
      >
        <label>Nom</label>
        <input
          type="text"
          name="LibelleSite"
          value={site.LibelleSite}
          onChange={handleInputChange}
        />
        <label>URL</label>
        <input
          type="text"
          name="link"
          value={site.link}
          onChange={handleInputChange}
        />
        <label>Image</label>
        <input type="file" accept="image/*" onChange={handleFileChange} />
        <label>Couleur</label>
        <ChromePicker color={color} onChange={handleColorChange} />
        <h2>Aperçu de l'Image</h2>
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Aperçu de l'image"
            style={{ width: "250px", height: "300px" }}
          />
        )}
        &emsp;
        <button>Ajouter/Modifier</button>
      </form>
      <div>
        <h2>Liste des Sites</h2>
        <ul>
          {sites.map((site) => (
            <li key={site.IdSite} onClick={() => handleSiteClick(site)}>
              {site.LibelleSite} - {site.link}
              &emsp;
              <button onClick={() => deleteSite(site.IdSite)}>Supprimer</button>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </div>
  );
}
