import React from "react";
import { IconButton, Avatar } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import "../css/Header.css";

export default function HeaderProfil({ username }) {
  return (
    <div className="header">
      <h1>Profil</h1>
      <Link to="/home">
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
      </Link>
    </div>
  );
}
